import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "a-particle-generator-pushed-to-20000-elements-with-canvas"
    }}>{`A particle generator pushed to 20,000 elements with Canvas`}</h1>
    <p>{`Our `}<a parentName="p" {...{
        "href": "/redux-animation/1"
      }}>{`SVG-based particle generator`}</a>{` caps out at a few thousand elements. Animation becomes slow as times between iterations of our game loop increase.`}</p>
    <p>{`Old elements leave the screen and get pruned faster than we can create new
ones. This creates a natural upper limit to how many elements we can push into
the page.`}</p>
    <p>{`We can render many more elements if we take out SVG and use HTML5 Canvas
instead. I was able to push the code up to almost 20,000 smoothly animated
elements. Then JavaScript became the bottleneck.`}</p>
    <p>{`Well, I say JavaScript was the bottleneck, but monitor size plays a role too.
It goes up to 20,000 on my laptop screen, juuuust grazes 30,000 on my large
desktop monitor, and averages about 17,000 on my iPhone 5SE.`}</p>
    <p>{`Friends with newer laptops got it up to 35,000.`}</p>
    <p>{`You can see it in action hosted on
`}<a parentName="p" {...{
        "href": "https://swizec.github.io/react-particles-experiment/"
      }}>{`Github pages`}</a>{`.`}</p>
    <iframe src="https://swizec.github.io/react-particles-experiment/" style={{
      width: "100%",
      border: 0,
      height: "400px"
    }} />
    <p>{`We're keeping most of our existing code. The real changes happen in
`}<inlineCode parentName="p">{`src/components/index.jsx`}</inlineCode>{`, where a Konva stage replaces the `}<inlineCode parentName="p">{`<svg>`}</inlineCode>{` element,
and in `}<inlineCode parentName="p">{`src/components/Particles.jsx`}</inlineCode>{`, where we change what we render. There's
a small tweak in the reducer to generate more particles per tick.`}</p>
    <p>{`You should go into your particle generator directory, install Konva and
react-konva, and then make the changes below. Trying things out is better than
just reading my code 😉`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-{caption=\"Install",
        "metastring": "Konva\"}",
        "Konva\"}": true
      }}>{`$ npm install --save konva react-konva
`}</code></pre>
    <p><em parentName="p">{`react-konva is a thin wrapper on Konva itself. There's no need to think
about it as its own thing. For the most part, you can go into the Konva docs,
read about something, and it Just Works™ in react-konva.`}</em></p>
    <h2 {...{
      "id": "preparing-a-canvas-layer"
    }}>{`Preparing a canvas layer`}</h2>
    <p>{`Our changes start in `}<inlineCode parentName="p">{`src/components/index.jsx`}</inlineCode>{`. We have to throw away the
`}<inlineCode parentName="p">{`<svg>`}</inlineCode>{` element and replace it with a Konva stage.`}</p>
    <p>{`You can think of a Konva stage as a Canvas element with a bunch of helper
methods attached. Some of them Konva uses internally; others are exposed as an
API. Functions like exporting to an image file, detecting intersections, etc.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/components/index.jsx

// ...
import { Stage } from 'react-konva';

// ...
class App extends Component {
    // ..
    render() {
        return (
            // ..
                     <Stage width={this.props.svgWidth} height={this.props.svgHeight}>
                         <Particles particles={this.props.particles} />

                     </Stage>

                 </div>
                 <Footer N={this.props.particles.length} />
             </div>
        );
    }
}
`}</code></pre>
    <p>{`We import `}<inlineCode parentName="p">{`Stage`}</inlineCode>{` from `}<inlineCode parentName="p">{`react-konva`}</inlineCode>{`, then use it instead of the `}<inlineCode parentName="p">{`<svg>`}</inlineCode>{`
element in the `}<inlineCode parentName="p">{`render`}</inlineCode>{` method. It gets a `}<inlineCode parentName="p">{`width`}</inlineCode>{` and a `}<inlineCode parentName="p">{`height`}</inlineCode>{`.`}</p>
    <p>{`Inside, we render the `}<inlineCode parentName="p">{`Particles`}</inlineCode>{` component. It's going to create a Konva layer
and use low-level Canvas methods to render particles as sprites.`}</p>
    <h2 {...{
      "id": "using-sprites-for-max-redraw-speed"
    }}>{`Using sprites for max redraw speed`}</h2>
    <p>{`Our `}<a parentName="p" {...{
        "href": "/redux-animation/1#particle"
      }}>{`SVG-based Particles`}</a>{`
component was simple. Iterate through a list of particles, render a
`}<inlineCode parentName="p">{`<Particle>`}</inlineCode>{` component for each.`}</p>
    <p>{`We're going to completely rewrite that. Our new approach goes like this:`}</p>
    <ol {...{
      "start": 0
    }}>
      <li parentName="ol">{`Cache a sprite on `}<inlineCode parentName="li">{`componentDidMount`}</inlineCode></li>
      <li parentName="ol">{`Clear canvas`}</li>
      <li parentName="ol">{`Redraw all particles`}</li>
      <li parentName="ol">{`Repeat`}</li>
    </ol>
    <p>{`Because the new approach renders a flat image, and because we don't care about
interaction with individual particles, we can get rid of the `}<inlineCode parentName="p">{`Particle`}</inlineCode>{`
component. The unnecessary layer of nesting was slowing us down.`}</p>
    <p>{`The new `}<inlineCode parentName="p">{`Particles`}</inlineCode>{` component looks like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/components/Particles.jsx

import React, { Component } from "react"
import { FastLayer } from "react-konva"

class Particles extends Component {
  layerRef = React.createRef()

  componentDidMount() {
    this.canvas = this.layerRef.current.canvas._canvas
    this.canvasContext = this.canvas.getContext("2d")

    this.sprite = new Image()
    this.sprite.src = "https://i.imgur.com/m5l6lhr.png"
  }

  drawParticle(particle) {
    let { x, y } = particle

    this.canvasContext.drawImage(this.sprite, 0, 0, 128, 128, x, y, 15, 15)
  }

  componentDidUpdate() {
    let particles = this.props.particles

    console.time("drawing")
    this.canvasContext.clearRect(0, 0, this.canvas.width, this.canvas.height)

    for (let i = 0; i < particles.length; i++) {
      this.drawParticle(particles[i])
    }
    console.timeEnd("drawing")
  }

  render() {
    return <FastLayer ref={this.layerRef} listening="false" />
  }
}

export default Particles
`}</code></pre>
    <p>{`40 lines of code is a lot all at once. Let's walk through step by step.`}</p>
    <h3 {...{
      "id": "componentdidmount"
    }}>{`componentDidMount`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/components/Particles.jsx

// ...
componentDidMount() {
    this.canvas = this.refs.layer.canvas._canvas;
    this.canvasContext = this.canvas.getContext('2d');

    this.sprite = new Image();
    this.sprite.src = 'https://i.imgur.com/m5l6lhr.png';
}
`}</code></pre>
    <p>{`React calls `}<inlineCode parentName="p">{`componentDidMount`}</inlineCode>{` when our component first renders. We use it to
set up 3 instance properties.`}</p>
    <p><inlineCode parentName="p">{`this.canvas`}</inlineCode>{` is a reference to the HTML5 Canvas element. We get it through a
ref to the Konva layer, then spelunk through Konva internals to get the canvas
itself. As the `}<inlineCode parentName="p">{`_`}</inlineCode>{` prefix indicates, Anton Lavrenov did not intend this to be a
public API.`}</p>
    <p>{`Thanks to JavaScript's permissiveness, we can use it anyway. 🙌`}</p>
    <p><inlineCode parentName="p">{`this.canvasContext`}</inlineCode>{` is a reference to our canvas's
`}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D"
      }}>{`CanvasRenderingContext2D`}</a>{`.
It's the interface we use to draw basic shapes, perform transformations, and so
on. Context is the only part of canvas you ever interact with as a developer.`}</p>
    <p>{`Why it's not just Canvas, I don't know.`}</p>
    <p><inlineCode parentName="p">{`this.sprite`}</inlineCode>{` is a cached image. A small minion that we are going to copy-paste
all over as our particle. Creating a new image object with `}<inlineCode parentName="p">{`new Image()`}</inlineCode>{` and
setting the `}<inlineCode parentName="p">{`src`}</inlineCode>{` property downloads our sprite from the internet into browser
memory.`}</p>
    <p>{`It looks like this:`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.imgur.com/m5l6lhr.png",
        "alt": "Our minion particle"
      }}></img></p>
    <p>{`You might think it's unsafe to copy references to rendered elements into
component properties like that, but it's okay. Our render function always
renders the same thing, so the reference never changes. It just makes our code
cleaner.`}</p>
    <p>{`Should our component unmount and re-mount, React will call `}<inlineCode parentName="p">{`componentDidMount`}</inlineCode>{`
again and update our reference.`}</p>
    <h3 {...{
      "id": "drawparticle"
    }}>{`drawParticle`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/components/Particles.jsx

// ...
drawParticle(particle) {
    let { x, y } = particle;

    this.canvasContext.drawImage(this.sprite, 0, 0, 128, 128, x, y, 15, 15);
}
`}</code></pre>
    <p><inlineCode parentName="p">{`drawParticle`}</inlineCode>{` draws a single particle on the canvas. It gets coordinates from
the `}<inlineCode parentName="p">{`particle`}</inlineCode>{` argument and uses `}<inlineCode parentName="p">{`drawImage`}</inlineCode>{` to copy our sprite into position.`}</p>
    <p>{`We use the whole sprite, corner `}<inlineCode parentName="p">{`(0, 0)`}</inlineCode>{` to corner `}<inlineCode parentName="p">{`(128, 128)`}</inlineCode>{`. That's how big
our sprite is. And we copy it to position `}<inlineCode parentName="p">{`(x, y)`}</inlineCode>{` with a width and height of
`}<inlineCode parentName="p">{`15`}</inlineCode>{` pixels.`}</p>
    <p><inlineCode parentName="p">{`drawImage`}</inlineCode>{` is the fastest method I've found to put pixels on canvas. I don't
know why it's so fast, but here's a
`}<a parentName="p" {...{
        "href": "https://jsperf.com/canvas-drawimage-vs-putimagedata/3"
      }}>{`helpful benchmark`}</a>{` so
you can see for yourself.`}</p>
    <h3 {...{
      "id": "componentdidupdate"
    }}>{`componentDidUpdate`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/components/Particles.jsx

// ...
componentDidUpdate() {
    let particles = this.props.particles;

    console.time('drawing');
    this.canvasContext.clearRect(0, 0, this.canvas.width, this.canvas.height);

    for (let i = 0; i < particles.length; i++) {
        this.drawParticle(particles[i]);
    }
    console.timeEnd('drawing');
}
`}</code></pre>
    <p><inlineCode parentName="p">{`componentDidUpdate`}</inlineCode>{` is where the magic happens. React calls this lifecycle
method every time our list of particles changes. `}<em parentName="p">{`After`}</em>{` the `}<inlineCode parentName="p">{`render`}</inlineCode>{` method.`}</p>
    <p>{`Just like the D3 blackbox approach, we move rendering out of the `}<inlineCode parentName="p">{`render`}</inlineCode>{`
method and into `}<inlineCode parentName="p">{`componentDidUpdate`}</inlineCode>{`.`}</p>
    <p>{`Here's how it works:`}</p>
    <ol>
      <li parentName="ol"><inlineCode parentName="li">{`this.canvasContext.clearRect`}</inlineCode>{` clears the entire canvas from coordinate
`}<inlineCode parentName="li">{`(0, 0)`}</inlineCode>{` to coordinate `}<inlineCode parentName="li">{`(width, height)`}</inlineCode>{`. We delete everything and make the
canvas transparent.`}</li>
      <li parentName="ol">{`We iterate our `}<inlineCode parentName="li">{`particles`}</inlineCode>{` list with a `}<inlineCode parentName="li">{`for`}</inlineCode>{` loop and call `}<inlineCode parentName="li">{`drawParticle`}</inlineCode>{` on
each element.`}</li>
    </ol>
    <p>{`Clearing and redrawing the canvas is faster than moving individual particles.
For loops are faster than `}<inlineCode parentName="p">{`.map`}</inlineCode>{` or any other form of iteration. I tested. A
lot.`}</p>
    <p>{`Open your browser console and see how long each frame takes to draw. The
`}<inlineCode parentName="p">{`console.time`}</inlineCode>{` - `}<inlineCode parentName="p">{`console.timeEnd`}</inlineCode>{` pair measures how long it takes your code to
get from `}<inlineCode parentName="p">{`time`}</inlineCode>{` to `}<inlineCode parentName="p">{`timeEnd`}</inlineCode>{`. You can have as many of these timers running as
you want as long as you give them different names.`}</p>
    <h3 {...{
      "id": "render"
    }}>{`render`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/components/Particles.jsx

// ...
render() {
    return (
        <FastLayer ref={this.layerRef} listening="false" />
    );
}
`}</code></pre>
    <p>{`After all that work, our `}<inlineCode parentName="p">{`render`}</inlineCode>{` method is quite short.`}</p>
    <p>{`We render a Konva `}<inlineCode parentName="p">{`FastLayer`}</inlineCode>{`, give it a `}<inlineCode parentName="p">{`ref`}</inlineCode>{` and turn off `}<inlineCode parentName="p">{`listening`}</inlineCode>{` for
mouse events. That makes the fast layer even faster.`}</p>
    <p>{`Ideas for this combination of settings came from Konva's official
`}<a parentName="p" {...{
        "href": "https://konvajs.github.io/docs/performance/All_Performance_Tips.html"
      }}>{`performance tips`}</a>{`
documentation. This makes sense when you think about it.`}</p>
    <p>{`A `}<inlineCode parentName="p">{`FastLayer`}</inlineCode>{` is faster than a `}<inlineCode parentName="p">{`Layer`}</inlineCode>{`. It's in the name. Ignoring mouse events
means you don't have to keep track of elements. It reduces computation.`}</p>
    <p>{`This was empirically the fastest solution with the most particles on screen.`}</p>
    <h2 {...{
      "id": "but-why-swizec"
    }}>{`But why, Swizec?`}</h2>
    <p><video parentName="p" {...{
        "style": {
          "margin": "auto auto",
          "display": "block",
          "maxWidth": "80%"
        },
        "autoPlay": true,
        "loop": true,
        "muted": true,
        "playsInline": true,
        "loading": "lazy"
      }}>{`
            `}<source parentName="video" {...{
          "src": "https://media0.giphy.com/media/s239QJIh56sRW/giphy-loop.mp4?cid=4ac046a25gdzg3xgmqwc2r2g6bppqn74mcplu3aiz39qdno1&rid=giphy-loop.mp4&ct=g",
          "type": "video/mp4"
        }}></source>{`
        `}</video></p>
    <p>{`I'm glad you asked. This was a silly example. I devised the experiment because
at my first React+D3 workshop somebody asked, `}<em parentName="p">{`"What if we have thousands of
datapoints, and we want to animate all of them?"`}</em>{`. I didn't have a good answer.`}</p>
    <p>{`Now I do. You put them in Canvas. You drive the animation with a game loop.
You're good.`}</p>
    <p>{`You can even do it as an overlay. Have an SVG for your graphs and charts,
overlay with a transparent canvas for your high speed animation.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      